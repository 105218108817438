<template>
  <div>
    <div class="tab-border mb-4">
      <div v-if="isOrganizer || booking.jeton_diffusion === true">
        <div
          class="flex justify-between cursor-pointer tab"
          @click="shareOpen = !shareOpen"
        >
          <div class="flex flex-col items-center justify-center">
            <DoiText :textColor="3" :fontFamily="6"
              >PARTAGE DU LIEN D'INVITATION</DoiText
            >
          </div>
          <div
            class="bg-red flex items-center justify-center text-white p-2 w-12 h-12"
          >
            <DoiText
              v-if="!shareOpen"
              type="span"
              class="material-icons"
              :fontSize="5"
              >keyboard_arrow_down</DoiText
            >
            <DoiText
              v-if="shareOpen"
              type="span"
              class="material-icons"
              :fontSize="5"
              >keyboard_arrow_up</DoiText
            >
          </div>
        </div>
        <div v-show="shareOpen">
          <div class="flex flex-col py-4">
            <div class="text-center w-full">
              <DoiHeader
                :level="4"
                :fontFamily="1"
                :headerColor="0"
                class="my-3 invitation-title"
                >INVITEZ VOS AMIS !</DoiHeader
              >
              <DoiText :textColor="3" :fontFamily="5" :fontSize="3" class="mb-8"
                >Pour partager ce lien, rien de plus simple : copiez le puis
                envoyez le par SMS, WhatsApp, ... <br />
                Vos amis n'auront plus qu'à cliquer sur le lien, valider votre
                invitation et rejoindre la partie !</DoiText
              >
              <div class="flex w-full flex-col lg:flex-row items-center">
                <div class="flex-1 mb-2 lg:mb-0 lg:mr-3 w-full">
                  <input
                    id="inputLink"
                    type="text"
                    readonly
                    :value="`${getLocation()}invitation/${
                      booking.id_reservation
                    }/${jetonInvitation}`"
                    class="appearance-none w-full h-10 px-3 leading-tight focus:outline-none focus:shadow-outline border text-sm"
                  />
                </div>
                <DoiButton @click="copyToClipBoard()" class="w-full lg:w-40"
                  >COPIER</DoiButton
                >
              </div>
            </div>
            <div
              v-if="booking.hasOwnProperty('jeton_diffusion') && isOrganizer"
              class="text-center w-full flex flex-col justify-center items-center"
            >
              <DoiHeader
                :level="4"
                :fontFamily="1"
                :headerColor="0"
                class="my-3 invitation-title"
                >PARTAGE DU LIEN D'INVITATION</DoiHeader
              >
              <DoiText :textColor="3" :fontFamily="5" :fontSize="3" class="mb-2"
                >L'autorisation de partage du lien d'invitation, permet à vos
                invités d'avoir accès au lien d'invitation et de pouvoir à leur
                tour inviter d'autres personnes.</DoiText
              >
              <div>
                <DoiSwitch @input="bookingShare" :checked="isShared" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isOrganizer" class="tab-border mb-4">
      <div>
        <div
          class="border flex justify-between cursor-pointer tab"
          @click="friendsOpen = !friendsOpen"
        >
          <div class="flex flex-col items-center justify-center">
            <DoiText :textColor="3" :fontFamily="6"
              >VOS COÉQUIPIERS HABITUELS</DoiText
            >
          </div>
          <div
            class="bg-fa-red flex items-center justify-center text-white p-2 w-12 h-12"
          >
            <DoiText
              v-if="!friendsOpen"
              type="span"
              class="material-icons"
              :fontSize="5"
              >keyboard_arrow_down</DoiText
            >
            <DoiText v-else type="span" class="material-icons" :fontSize="5"
              >keyboard_arrow_up</DoiText
            >
          </div>
        </div>
        <div class="tab-content">
          <div v-if="friendsOpen" class="flex justify-center flex-col pt-8">
            <div class="text-center mb-4">
              <DoiText :textColor="3" :fontFamily="5" :fontSize="3"
                >Choisissez avec qui vous voulez jouer, puis validez votre
                invitation.<br />Ils recevront une notification pour vous
                rejoindre.</DoiText
              >
            </div>
            <div class="flex flex-wrap flex-1">
              <div
                v-for="(items, index) in contactList"
                :key="index"
                class="flex items-center w-full"
              >
                <div
                  v-if="items.identifiant !== null"
                  class="flex w-full mt-4 items-center"
                >
                  <div class="mr-2 flex items-center justify-end w-full">
                    <label
                      class="cursor-pointer label-container whitespace-nowrap ml-2 border px-3 py-2 justify-between flex items-center"
                      :for="`items_${items.id}`"
                    >
                      <div class="text-right">
                        <DoiText :textColor="0" :fontFamily="6" :fontSize="3">{{
                          items.identifiant
                        }}</DoiText>
                        <DoiText
                          :textColor="3"
                          :fontFamily="4"
                          :fontSize="3"
                          class="ml-2"
                          >({{ items.prenom }} {{ items.nom }})</DoiText
                        >
                      </div>
                      <input
                        @change="_userSelect(items.id)"
                        :ref="`items_${items.id}`"
                        type="checkbox"
                        value="1"
                        :id="`items_${items.id}`"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="contactList.length > 0">
              <div class="text-center mx-auto my-4 text-red-600 font-bold">
                {{ errorMessageContactList }}
              </div>
              <div class="flex justify-center mt-8 mb-4">
                <DoiButton @click="_sendInvitation()"
                  >INVITER CES JOUEURS</DoiButton
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4 tab-border" v-if="isOrganizer">
      <div
        class="border flex justify-between cursor-pointer tab"
        @click="contactInvit = !contactInvit"
      >
        <div class="flex flex-col items-center justify-center">
          <DoiText :textColor="3" :fontFamily="6">MA FAMILLE</DoiText>
        </div>
        <div
          class="bg-fa-red flex items-center justify-center text-white p-2 w-12 h-12"
        >
          <DoiText
            v-if="!contactInvit"
            type="span"
            class="material-icons"
            :fontSize="5"
            >keyboard_arrow_down</DoiText
          >
          <DoiText
            v-if="contactInvit"
            type="span"
            class="material-icons"
            :fontSize="5"
            >keyboard_arrow_up</DoiText
          >
        </div>
      </div>
      <div class="tab-content">
        <div v-if="contactInvit" class="flex justify-center flex-col pt-4">
          <DoiText
            class="mb-2 text-center"
            :fontSize="3"
            :fontFamily="5"
            :textColor="3"
            >Dans cet onglet, vous pouvez rattacher à votre compte des personnes
            n'ayant pas de smartphone ou de compte Fit Arena (exemple :
            personnes mineures).</DoiText
          >
          <DoiText
            class="mb-2 text-center"
            :fontSize="3"
            :fontFamily="5"
            :textColor="3"
            >Le paiement de leur séance sera à votre charge et sera prélevé sur
            le compte bancaire renseigné.</DoiText
          >
          <div
            v-if="contactListInvit && contactListInvit.length > 0"
            class="flex flex-wrap -mx-3"
          >
            <div
              v-for="(contactData, index) in contactListInvit"
              :key="index"
              class="w-full lg:w-3/12 flex-wrap p-3"
            >
              <ContactCard
                :contactData="contactData"
                :disableAction="true"
                @onSelect="vSelect($event)"
              />
            </div>
            <div class="w-full p-3 lg:w-3/12">
              <div
                @click="openNewContactForm = !openNewContactForm"
                class="flex h-80 items-center justify-center rounded-sm border-2 border-gray-100 transition transition-300 cursor-pointer transition-300 hover:bg-gray-100"
              >
                <div
                  class="relative flex flex-col items-center justify-center text-center"
                >
                  <img src="@/assets/icons/utils/compte/add_contact.svg" />
                  <DoiText
                    :textColor="0"
                    :fontFamily="1"
                    :fontSize="3"
                    class="mt-4 px-4"
                    >Ajouter un membre à ma famille</DoiText
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-else class="flex justify-center">
            <DoiText :fontFamily="6" :textColor="3"
              >Vous n'avez aucun membre enregistré dans votre famille.</DoiText
            >
            <div class="w-full p-3 lg:w-3/12">
              <div
                @click="openNewContactForm = !openNewContactForm"
                class="flex mt-4 h-80 items-center justify-center rounded-sm border-2 border-gray-100 transition-300 cursor-pointer transition transition-300 hover:bg-gray-100"
              >
                <div
                  class="relative flex flex-col items-center justify-center text-center"
                >
                  <img src="@/assets/icons/utils/compte/add_contact.svg" />
                  <DoiText
                    :textColor="0"
                    :fontFamily="1"
                    :fontSize="3"
                    class="mt-4 px-4"
                    >Ajouter un membre à ma famille</DoiText
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center my-8">
            <button
              v-if="contactListInvit && contactListInvit.length > 0"
              class="button red"
              :disabled="isDisabled"
              @click="_sendContactInvitation()"
            >
              INVITER
              <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <Modal
      title="AJOUTER UN MEMBRE"
      headerColor="red"
      :headerIcon="require('@/assets/icons/utils/compte/person.svg')"
      :open="openNewContactForm"
      size="xs"
      :keepratio="true"
      @close-modal="openNewContactForm = false"
    >
      <template slot="modal_content">
        <form @submit.prevent="submit" id="form">
          <div class="flex justify-center flex-col items-center relative mb-10">
            <img
              width="130"
              height="130"
              :src="require('@/assets/icons/utils/compte/img_default.svg')"
              id="img"
            />
            <DoiInput
              class="underline font-input cursor-pointer"
              label="Ajouter une image"
              type="file"
              :hide="true"
              ref="avatar"
              @change="handleChangeAvatar"
            />
            <DoiText :textColor="1" :fontFamily="6" class="text-center mx-auto"
              >Veuillez sélectionner un avatar dont la taille maximum est de 5
              Mo.</DoiText
            >
          </div>
          <div class="form-group mb-4">
            <DoiInput
              v-model.trim="$v.sNom.$model"
              :error="!sNomValide && sNom === ''"
              placeholder="Nom *"
            />
            <DoiText
              italic
              :textColor="0"
              :fontSize="1"
              v-if="!sNomValide && sNom === ''"
              >Ce champ est requis</DoiText
            >
          </div>
          <div class="form-group mb-4">
            <DoiInput
              v-model.trim="$v.sPrenom.$model"
              :error="!sPrenomValide && sPrenom === ''"
              placeholder="Prénom *"
            />
            <DoiText
              italic
              :textColor="0"
              :fontSize="1"
              v-if="!sPrenomValide && sPrenom === ''"
              >Ce champ est requis</DoiText
            >
          </div>
          <div class="form-group mb-4">
            <!-- <DoiInput v-model.trim="$v.sNomJoueur.$model" :error="!sNomJoueurValide && sNomJoueur === ''" placeholder="Pseudonyme *" /> -->
            <input
              maxlength="12"
              required
              v-model.trim="$v.sNomJoueur.$model"
              :error="!sNomJoueurValide && sNomJoueur === ''"
              placeholder="Pseudonyme *"
              class="doi-input"
            />
            <DoiText
              italic
              :textColor="0"
              :fontSize="1"
              v-if="!sNomJoueurValide && sNomJoueur === ''"
              >Ce champ est requis</DoiText
            >
          </div>
          <div class="text-center mx-auto mb-4 mt-10">
            <DoiText :fontFamily="6" :textColor="3" :fontSize="3"
              >Ce membre est-il mineur ?</DoiText
            >
            <DoiSwitch @input="setbMineur" :id="1" />
          </div>
        </form>
      </template>
      <template slot="modal_footer">
        <DoiText
          :fontFamily="6"
          :textColor="3"
          :fontSize="2"
          class="text-center px-4"
          >Cette personne n'aura pas de compte individuel et sera rattachée au
          vôtre.</DoiText
        >
        <div class="flex pb-8 px-8 pt-4 justify-center">
          <DoiButton
            @click="openNewContactForm = false"
            class="w-6/12 mr-4 tracking-widest"
            :bgColor="2"
            :bgHover="3"
            >ANNULER</DoiButton
          >
          <DoiButton
            @click="submit"
            type="submit"
            class="tracking-widest w-6/12"
            :bgHover="1"
          >
            ENREGISTRER
            <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
          </DoiButton>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/molecules/Modal";
import ContactCard from "@/views/contact/ContactCard";

import {
  getContact,
  getContactsV2,
  setNewContactV2,
} from "@/api/ContactService";
import {
  getUserAlreadyPlayedV2,
  shareReservationV2,
  getUuidInvitationV2,
} from "@/api/BookingService";
import { setUserAvatar } from "@/api/UserService";

import { required } from "vuelidate/lib/validators";
import { mapMutations, mapActions } from "vuex";

export default {
  name: "BookingGamer",
  data() {
    return {
      friendsOpen: false,
      errorMessageContactList: "",
      contactList: [],
      selectedUser: [],
      shareOpen: false,
      contactInvit: false,
      contactListInvit: [],
      contactInvitation: [],
      contactId: 0,
      aReponse: [],
      openNewContactForm: false,
      sNom: "",
      sNomValide: true,
      sPrenom: "",
      sPrenomValide: true,
      sNomJoueur: "",
      sNomJoueurValide: true,
      showSpinner: false,
      loading: false,
      type: "add",
      avatar: "",
      bEquipementAdapte: 0,
      jetonInvitation: "",
    };
  },
  props: {
    booking: Object,
    isOrganizer: {
      type: Boolean,
      default: false,
    },
  },
  components: { ContactCard, Modal },
  validations: {
    sNom: { required },
    sPrenom: { required },
    sNomJoueur: { required },
  },
  created() {
    this.nIdUtilisateur = this.$store.getters["UserModule/get_user_id"];
  },
  async mounted() {
    await this.getUuidInvitation();
    this.getUserAlreadyPlayed();
    this.checkInvitation();
    this.contactId = this.$store.getters["UserModule/get_contact_id"];
    if (!this.isOrganizer || this.booking.jeton_diffusion === true)
      this.shareOpen = true;
  },
  computed: {
    isDisabled() {
      return this.showSpinner;
    },
    isShared() {
      return this.booking.jeton_diffusion;
    },
  },
  methods: {
    ...mapMutations("ReservationListModule", ["setReservationPartageable"]),
    ...mapActions("InvitationModule", ["valideInvitation"]),
    ...mapActions("ReservationListModule", ["retrieveReservations"]),
    setbMineur(val) {
      this.bMineur = val ? "1" : "0";
    },
    async getUuidInvitation() {
      const response = await getUuidInvitationV2(
        this.$props.booking.id_reservation
      );
      if (response) this.jetonInvitation = response[0].jetonInvitation;
    },
    async bookingShare(event) {
      this.setReservationPartageable({
        id_reservation: this.booking.id_reservation,
        statut_partage: event,
      });
      const res = await shareReservationV2(this.booking.id_reservation, event);
      if (res) {
        if (event) {
          const message = "Votre réservation est maintenant partageable";
          if (screen.width > 640) {
            this.$toast.success(message);
          } else {
            this.$toast.success(message, {
              position: "top",
            });
          }
        } else {
          const message = "Votre réservation n'est maintenant plus partageable";

          if (screen.width > 640) {
            this.$toast.warning(message);
          } else {
            this.$toast.warning(message, {
              position: "top",
            });
          }
        }
      }
    },
    resetInfos() {
      this.sNom = "";
      this.sPrenom = "";
      this.sNomJoueur = "";
      this.avatar = "";
      this.bMineur = "0";
    },
    async checkInvitation() {
      const response = await getContactsV2();
      if (response) this.contactListInvit = response;
    },
    async _sendContactInvitation() {
      if (this.aReponse.length === 0) {
        const message = "Au moins un contact doit être sélectionné";
        if (screen.width > 640) {
          this.$toast.error(message);
        } else {
          this.$toast.error(message, {
            position: "top",
          });
        }
        return;
      }

      this.showSpinner = true;

      await this.valideInvitation({
        idReservation: this.$props.booking.id_reservation,
        jetonInvitation: this.jetonInvitation,
        responseData: this.aReponse,
      });

      this.$emit("close");
      this.showSpinner = false;
      await this.retrieveReservations();
    },
    vSelect(id) {
      const value = this.aReponse.find((e) => parseInt(e.id) === parseInt(id));

      if (value === undefined) {
        this.aReponse = [
          ...this.aReponse,
          { idUser: id, reponse: "validation" },
        ];
      } else {
        const tmp = this.aReponse;
        const index = tmp.findIndex((e) => parseInt(e.id) === parseInt(id));
        tmp.splice(index, 1);
        this.aReponse = [...tmp];
      }
    },
    vClickLabel(ref) {
      this.$refs[ref].checked = true;
    },
    getLocation() {
      return process.env.VUE_APP_URL;
    },
    copyToClipBoard() {
      const copyText = document.querySelector("#inputLink");
      copyText.select();
      document.execCommand("copy");
      const message = "Lien copié avec succès";
      if (screen.width > 640) {
        this.$toast.success(message);
      } else {
        this.$toast.success(message, {
          position: "top",
        });
      }
    },
    async getUserAlreadyPlayed() {
      const result = await getUserAlreadyPlayedV2();
      if (result !== undefined && result !== null) this.contactList = result;
    },
    _userSelect(id) {
      id = parseInt(id);
      const value = this.selectedUser.find((e) => e === id);

      if (value === undefined) {
        this.selectedUser = [...this.selectedUser, id];
      } else {
        const tmp = this.selectedUser;
        const index = tmp.findIndex((e) => e === id);
        tmp.splice(index, 1);
        this.selectedUser = tmp;
      }
    },
    async _sendInvitation() {
      this.errorMessageContactList = "";
      if (this.selectedUser.length === 0) {
        this.errorMessageContactList =
          "Veuillez sélectionner au moins un contact.";
        return;
      }
      const params = {
        reservationUuid: this.$props.booking.id_reservation,
        participantsIds: this.selectedUser,
        domaine: window.location.host,
      };

      this.$store.dispatch("ReservationListModule/sendInvitation", params);
      this.$emit("close");
    },
    async handleChangeAvatar($event) {
      const file = $event.target.files[0];
      var FR = new FileReader();

      FR.addEventListener("load", (e) => {
        document.getElementById("img").src = e.target.result;
        this.avatar = e.target.result;
      });

      FR.readAsDataURL(file);
    },
    submit() {
      this.showSpinner = true;
      this.$v.$touch();
      if (!this.sNom) this.sNomValide = false;
      if (!this.sPrenom) this.sPrenomValide = false;
      if (!this.sNomJoueur) this.sNomJoueurValide = false;

      if (this.$v.$invalid) {
        this.errorMessage =
          "Merci de vérifier vos informations et veuillez réessayer";
        this.showSpinner = false;
        this.open = true;
      } else {
        this.setUserData();
      }
    },
    async setUserData() {
      this.sNomValide = true;
      this.sPrenomValide = true;
      this.sNomJoueurValide = true;

      let data = {
        email: "",
        prenom: this.sPrenom,
        nom: this.sNom,
        portable: "",
        identifiant: this.sNomJoueur,
        mineur: this.bMineur === "1" ? true : false,
        plainPassword: self.crypto.randomUUID().split("")[0],
      };

      const result = await setNewContactV2(data).then(async () => {
        if (this.avatar !== "") {
          await setUserAvatar({
            identifiant: this.sNomJoueur,
            avatar: this.avatar,
          });
        }
      });

      if (result !== undefined && result !== null) {
        if (result.bSucces) {
          const message = "Contact ajouté avec succès";
          if (screen.width > 640) {
            this.$toast.success(message);
          } else {
            this.$toast.success(message, {
              position: "top",
            });
          }
        }
      }

      this.showSpinner = false;
      this.openNewContactForm = false;
      this.resetInfos();
      this.checkInvitation();
    },
    async getContact() {
      const userId = this.$store.getters["UserModule/get_user_id"];
      const response = await getContact(userId);

      if (response !== undefined && response !== null) {
        this.contactList = response;
        this.contactList.map(
          (contact) =>
            contact.sPrenom.length > 8 && contact.sPrenom.slice(0, 8) + "..."
        );
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.bg-red {
  background-color: $doi--color-color0;
}

input#inputLink {
  font-family: $doi--text-font-family4;
  color: $doi--color-color4;
}

.doi-input {
  width: 100%;
  padding: 10px 7px;
  border-bottom: solid 0.13rem rgb(225, 225, 225);
  background-color: transparent;
}
.doi-input::placeholder {
  color: $doi--color-color4;
  font-family: $doi--text-font-family6;
  font-size: 0.9rem;
}
.doi-input:focus {
  outline: none;
}

@media (max-width: 650px) {
  .invitation-title {
    font-size: $doi--header5;
  }
}
</style>
