<template>
  <div>
    <div class="bg-white rounded-md overflow-hidden">
      <div class="flex justify-center w-full mb-5">
        <DoiHeader
          :level="3"
          :fontFamily="1"
          class="text-2xl md:text-4xl tracking-wide"
          >MA FAMILLE</DoiHeader
        >
      </div>
      <div class="bg-fa-red h-0.5 mx-5" />
      <div class="w-full">
        <div v-if="loading" class="flex h-full justify-center items-center">
          <DoiSpinner :spinnerSize="1" :show="true" />
        </div>
        <div v-else-if="loading === false">
          <div v-if="contactList.length > 0" class="flex flex-wrap p-2">
            <div
              v-for="(contact, index) in contactList"
              :key="index"
              class="w-full lg:w-3/12 p-3 flex"
              :class="parseInt(contact.id) !== contactId ? 'visible' : 'hidden'"
            >
              <div class="w-full flex-wrap">
                <ContactCard
                  :contactData="contact"
                  @onEdit="setUserDataTmp($event, 'update')"
                  @onRefresh="getContact()"
                />
              </div>
            </div>
            <div class="w-full p-3 lg:w-3/12">
              <div
                @click="openNewContactForm = !openNewContactForm"
                class="flex h-80 items-center justify-center rounded-sm border-2 border-gray-100 transition transition-300 cursor-pointer transition-300 hover:bg-gray-100"
              >
                <div
                  class="relative flex flex-col items-center justify-center text-center"
                >
                  <img src="@/assets/icons/utils/compte/add_contact.svg" />
                  <DoiText
                    :textColor="0"
                    :fontFamily="1"
                    :fontSize="3"
                    class="mt-4 px-2"
                    >Ajouter un membre à ma famille</DoiText
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-else class="p-4 text-center">
            <DoiText :fontFamily="2" :textColor="3" :fontSize="4"
              >Vous n'avez actuellement aucun enfant dans votre liste.</DoiText
            >
            <DoiText :fontFamily="2" :textColor="3" :fontSize="4"
              >Pour en ajouter, veuillez cliquer sur le bouton "Ajouter un
              membre à ma famille" ci-dessous.</DoiText
            >
            <div class="w-full p-3 lg:w-3/12">
              <div
                @click="openNewContactForm = !openNewContactForm"
                class="flex h-80 items-center justify-center rounded-sm border-2 border-gray-100 transition transition-300 cursor-pointer transition-300 hover:bg-gray-100"
              >
                <div
                  class="relative flex flex-col items-center justify-center text-center"
                >
                  <img src="@/assets/icons/utils/compte/add_contact.svg" />
                  <DoiText
                    :textColor="0"
                    :fontFamily="1"
                    :fontSize="3"
                    class="mt-4 px-2"
                    >Ajouter un membre à ma famille</DoiText
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-fa-red h-0.5 mx-5 mb-5" />
    </div>

    <Modal
      title="AJOUTER UN MEMBRE"
      headerColor="red"
      :headerIcon="require('@/assets/icons/utils/compte/person.svg')"
      :open="openNewContactForm"
      size="xs"
      :keepratio="true"
      @close-modal="openNewContactForm = false"
    >
      <template slot="modal_content">
        <form @submit.prevent="submit" id="form">
          <div class="flex justify-center flex-col items-center relative mb-10">
            <img
              width="130"
              height="130"
              :src="require('@/assets/icons/utils/compte/img_default.svg')"
              id="img"
            />
            <DoiInput
              class="underline font-input cursor-pointer"
              label="Ajouter une image"
              type="file"
              :hide="true"
              ref="avatar"
              @change="handleChangeAvatar"
            />
            <DoiText :textColor="1" :fontFamily="6" class="text-center mx-auto"
              >Veuillez sélectionner un avatar dont la taille maximum est de 5
              Mo.</DoiText
            >
          </div>
          <div class="form-group mb-4">
            <DoiInput
              v-model.trim="$v.nom.$model"
              :error="!nomValide && nom === ''"
              placeholder="Nom *"
            />
            <DoiText
              italic
              :textColor="0"
              :fontSize="1"
              v-if="!nomValide && nom === ''"
              >Ce champ est requis</DoiText
            >
          </div>
          <div class="form-group mb-4">
            <DoiInput
              v-model.trim="$v.prenom.$model"
              :error="!prenomValide && prenom === ''"
              placeholder="Prénom *"
            />
            <DoiText
              italic
              :textColor="0"
              :fontSize="1"
              v-if="!prenomValide && prenom === ''"
              >Ce champ est requis</DoiText
            >
          </div>
          <div class="form-group mb-4">
            <input
              maxlength="12"
              required
              v-model.trim="$v.identifiant.$model"
              :error="!identifiantValide && identifiant === ''"
              placeholder="Pseudonyme *"
              class="doi-input"
            />
            <DoiText
              italic
              :textColor="0"
              :fontSize="1"
              v-if="!identifiantValide && identifiant === ''"
              >Ce champ est requis</DoiText
            >
          </div>
          <div class="text-center mx-auto mb-4 mt-10">
            <DoiText :fontFamily="6" :textColor="3" :fontSize="3"
              >Ce membre est-il mineur ?</DoiText
            >
            <DoiSwitch @input="setMineur" :checked="mineur" v-model="mineur" />
          </div>
        </form>
      </template>
      <template slot="modal_footer">
        <DoiText
          :fontFamily="6"
          :textColor="3"
          :fontSize="2"
          class="text-center px-4"
          >Cette personne n'aura pas de compte individuel et sera rattachée au
          vôtre.</DoiText
        >
        <div class="flex pb-8 px-8 pt-4 justify-center">
          <DoiButton
            @click="openNewContactForm = false"
            :disabled="submitStatus === 'PENDING'"
            class="w-6/12 mr-4 tracking-widest"
            :bgColor="2"
            :bgHover="3"
            >ANNULER</DoiButton
          >
          <DoiButton
            @click="submit"
            type="submit"
            :disabled="submitStatus === 'PENDING'"
            class="tracking-widest w-6/12"
            :bgHover="1"
          >
            ENREGISTRER
            <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
          </DoiButton>
        </div>
      </template>
    </Modal>
    <Modal
      title="GESTION DE LA FAMILLE"
      headerColor="red"
      :headerIcon="require('@/assets/icons/utils/compte/person.svg')"
      :open="openContactForm"
      size="xs"
      :keepratio="true"
      @close-modal="openContactForm = false"
    >
      <template slot="modal_content">
        <form @submit.prevent="submit" id="form">
          <div class="flex justify-center flex-col items-center relative mb-10">
            <DoiImage
              :rounded="true"
              size="9rem"
              v-if="previewImgAvatar !== ''"
              :src="previewImgAvatar"
              id="img"
            />
            <img
              v-else
              width="130"
              height="130"
              :src="require('@/assets/icons/utils/compte/img_default.svg')"
              id="img"
            />
            <DoiInput
              class="underline font-input cursor-pointer"
              label="Ajouter une image"
              type="file"
              :hide="true"
              ref="avatar"
              @change="handleChangeAvatar"
            />
            <DoiText :textColor="1" :fontFamily="6" class="text-center mx-auto"
              >Veuillez sélectionner un avatar dont la taille maximum est de 5
              Mo.</DoiText
            >
          </div>
          <div class="form-group mb-4">
            <DoiInput
              v-model.trim="$v.nom.$model"
              :error="!nomValide && nom === ''"
              placeholder="Nom *"
            />
            <DoiText
              italic
              :textColor="0"
              :fontSize="1"
              v-if="!nomValide && nom === ''"
              >Ce champ est requis</DoiText
            >
          </div>
          <div class="form-group mb-4">
            <DoiInput
              v-model.trim="$v.prenom.$model"
              :error="!prenomValide && prenom === ''"
              placeholder="Prénom *"
            />
            <DoiText
              italic
              :textColor="0"
              :fontSize="1"
              v-if="!prenomValide && prenom === ''"
              >Ce champ est requis</DoiText
            >
          </div>
          <div class="form-group mb-4">
            <input
              maxlength="12"
              required
              v-model.trim="$v.identifiant.$model"
              :error="!identifiantValide && identifiant === ''"
              placeholder="Pseudonyme *"
              class="doi-input"
            />
            <DoiText
              italic
              :textColor="0"
              :fontSize="1"
              v-if="!identifiantValide && identifiant === ''"
              >Ce champ est requis</DoiText
            >
          </div>
          <div class="text-center mx-auto mb-4 mt-10">
            <DoiText :fontFamily="6" :textColor="3" :fontSize="3"
              >Ce membre est-il mineur ?</DoiText
            >
            <DoiSwitch @input="setMineur" :checked="mineur" v-model="mineur" />
          </div>
        </form>
      </template>
      <template slot="modal_footer">
        <div class="flex p-8 justify-center">
          <DoiButton
            @click="openContactForm = false"
            :disabled="submitStatus === 'PENDING'"
            class="w-6/12 mr-4 tracking-widest"
            :bgColor="2"
            :bgHover="3"
            >ANNULER</DoiButton
          >
          <DoiButton
            @click="submit"
            type="submit"
            :disabled="submitStatus === 'PENDING'"
            class="tracking-widest w-6/12"
            :bgHover="1"
          >
            ENREGISTRER
            <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
          </DoiButton>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/molecules/Modal";
import Navigation from "@/components/organisms/Navigation";

import Profil from "@/views/user/Profil";
import ContactCard from "@/views/contact/ContactCard";

import {
  getContact,
  getContactsV2,
  setNewContactV2,
  setUpdateContactV2,
} from "@/api/ContactService";
import { setUserAvatar } from "@/api/UserService";

import { required } from "vuelidate/lib/validators";
import { generateImageUrlV2 } from "@/utils/imgUrlBuilder";

export default {
  name: "Contact",
  data() {
    return {
      showSection: "reservation",
      loading: false,
      openNewContactForm: false,
      openContactForm: false,
      contactList: [],
      contactId: null,
      showSpinner: false,
      submitStatus: "",
      nom: "",
      prenom: "",
      avatar: "",
      identifiant: "",
      rAvatar: "",
      id: "",
      mineur: false,
      nIdUtilisateur: 0,
      bEquipementAdapte: 0,
      type: "add",
      nomValide: true,
      prenomValide: true,
      identifiantValide: true,
    };
  },
  components: {
    Navigation,
    Profil,
    Modal,
    ContactCard,
  },
  validations: {
    nom: {
      required,
    },
    prenom: {
      required,
    },
    identifiant: {
      required,
    },
  },
  mounted() {
    if (this.getUserId !== null && this.getContactId !== null)
      this.getContact();
    this.loading = true;
  },
  computed: {
    previewImgAvatar() {
      if (this.avatar != "") return generateImageUrlV2(this.avatar);
      return "";
    },
    getUserId() {
      return this.$store.getters["UserModule/get_user_id"];
    },
    getContactId() {
      return this.$store.getters["UserModule/get_contact_id"];
    },
  },
  methods: {
    setMineur(val) {
      return (this.mineur = val);
    },
    setUserDataTmp(e, type = "add") {
      this.type = type;
      this.openContactForm = !this.openContactForm;

      if (type === "update") {
        this.nom = e.nom;
        this.prenom = e.prenom;
        this.avatar = e.hasOwnProperty("url") ? e.url : "";
        this.identifiant = e.identifiant;
        this.mineur = e.mineur;
        this.bEquipementAdapte = 0;
        this.id = e.id;
      }
    },
    async getContact() {
      const response = await getContactsV2();

      if (response !== undefined && response !== null) {
        this.contactList = response;
        this.contactList.map(
          (contact) =>
            contact.prenom.length > 8 && contact.prenom.slice(0, 8) + "..."
        );
      }

      this.loading = false;
    },
    async handleChangeAvatar($event) {
      const file = $event.target.files[0];
      var FR = new FileReader();

      FR.addEventListener("load", (e) => {
        document.getElementById("img").src = e.target.result;
        this.avatar = e.target.result;
      });

      FR.readAsDataURL(file);
    },
    submit() {
      this.showSpinner = true;
      this.$v.$touch();
      if (!this.nom) this.nomValide = false;
      if (!this.prenom) this.prenomValide = false;
      if (!this.identifiant) this.identifiantValide = false;

      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        this.errorMessage =
          "Merci de vérifier vos informations et veuillez réessayer";
        this.showSpinner = false;
        this.open = true;
      } else {
        this.setUserData();
      }
    },
    async setUserData() {
      this.nomValide = true;
      this.prenomValide = true;
      this.identifiantValide = true;
      let result = null;
      let message = "";

      const data = {
        email: "",
        prenom: this.prenom,
        nom: this.nom,
        portable: "",
        identifiant: this.identifiant,
        mineur: this.mineur,
        plainPassword: self.crypto.randomUUID().split("-")[0],
      };

      switch (this.type) {
        case "add":
          await setNewContactV2(data).then(async () => {
            if (this.avatar !== "") {
              await setUserAvatar({
                identifiant: this.identifiant,
                avatar: this.avatar,
              });
            }
          });
          message = "Contact ajouté avec succès";
          break;
        case "update":
          await setUpdateContactV2(data, this.id).then(async () => {
            if (this.avatar !== "" && this.avatar.includes("data:")) {
              await setUserAvatar({
                user: this.id,
                avatar: this.avatar,
              });
            }
          });
          message = "Contact modifié avec succès";
          break;
      }

      if (result !== undefined && result !== null) {
        if (result.bSucces) {
          if (screen.width > 640) {
            this.$toast.success(message);
          } else {
            this.$toast.success(message, {
              position: "top",
            });
          }
        }
      }

      this.resetInfos();
      this.getContact();
    },
    resetInfos() {
      this.nom = "";
      this.prenom = "";
      this.identifiant = "";
      this.type = "add";
      this.mineur = false;
      this.nIdUtilisateur = 0;
      this.bEquipementAdapte = 0;
      this.showSpinner = false;
      this.openNewContactForm = false;
      this.openContactForm = false;
    },
  },
  watch: {
    getUserId(val) {
      this.nIdUtilisateur = val;
      this.getContact();
    },
    getContactId(val) {
      this.contactId = val;
      this.getContact();
    },
    openContactForm(val) {
      if (val === false) {
        this.nom = "";
        this.prenom = "";
        this.avatar = "";
        this.identifiant = "";
        this.mineur = false;
        this.bEquipementAdapte = 0;
        this.id = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.doi-input {
  width: 100%;
  padding: 10px 7px;
  border-bottom: solid 0.13rem rgb(225, 225, 225);
  background-color: transparent;
}
.doi-input::placeholder {
  color: $doi--color-color4;
  font-family: $doi--text-font-family6;
  font-size: 0.9rem;
}
.doi-input:focus {
  outline: none;
}
</style>
