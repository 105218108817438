<template>
  <div class="flex flex-1 justify-center">
    <div
      class="md:container items-center px-2 md:px-20 w-full justify-center h-full"
    >
      <!-- STEPPER -->
      <div class="flex justify-between items-center mx-10">
        <div class="w-1/12 flex justify-center">
          <div
            id="step1"
            class="flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 ring-2 ring-offset-4 rounded-lg ring-gray-200 font-text-active"
          >
            1
          </div>
        </div>
        <div v-if="step1 === true" class="h-0.5 w-4/12 line" />
        <div v-if="step1 === false" class="h-0.5 w-4/12 red-stepper-line" />
        <div class="w-1/12 flex justify-center">
          <div
            id="step2"
            class="flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 ring-2 ring-offset-4 rounded-lg ring-gray-200"
            :class="step1 === false ? 'font-text-active' : 'font-text-inactive'"
          >
            2
          </div>
        </div>
        <div v-if="step3 === false" class="h-0.5 w-4/12 line" />
        <div v-if="step3 === true" class="h-0.5 w-4/12 red-stepper-line" />
        <div class="w-1/12 flex justify-center">
          <div
            id="step3"
            class="flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 ring-2 ring-offset-4 rounded-lg ring-gray-200"
            :class="step3 == true ? 'font-text-active' : 'font-text-inactive'"
          >
            3
          </div>
        </div>
      </div>
      <div class="flex justify-between items-center">
        <div class="w-4/12">
          <DoiText
            :textColor="0"
            :fontFamily="5"
            :fontSize="1"
            class="uppercase mt-4 step1"
            >Récapitulatif du panier</DoiText
          >
        </div>
        <div class="w-4/12">
          <DoiText
            :textColor="0"
            :fontFamily="5"
            :fontSize="1"
            class="uppercase mt-4 text-center"
          >
            {{ get_cart.tarif ? "Paiement" : "Réserver" }}</DoiText
          >
        </div>
        <div class="w-4/12">
          <DoiText
            :textColor="0"
            :fontFamily="5"
            :fontSize="1"
            class="uppercase mt-4 text-right step3"
            >Confirmation</DoiText
          >
        </div>
      </div>

      <div v-if="isLoading || isLoaded">
        <div class="flex flex-1 justify-center items-center">
          <DoiSpinner :spinnerSize="1" />
        </div>
      </div>

      <!-- STEP 1 -->
      <div v-if="step1 === true && !isLoaded">
        <div class="mt-10">
          <router-link to="/" class="flex items-center mx-4">
            <img src="@/assets/img/back-v2.png" alt="retour page precedente" />
            <DoiText
              type="span"
              class="ml-2"
              :textColor="3"
              :fontFamily="5"
              :fontSize="3"
              >RETOUR</DoiText
            >
          </router-link>
        </div>

        <div class="flex flex-1 my-8 items-center">
          <div class="red-line"></div>
          <DoiText
            :fontSize="5"
            :textColor="1"
            :fontFamily="0"
            class="uppercase ml-2"
            >VOTRE SÉLECTION</DoiText
          >
        </div>
        <div class="mt-8 mb-4 flex justify-between md:ml-12">
          <CartPaymentList
            :cartList="get_cart"
            :shareBill="shareBill"
            :readOnly="true"
            :v2="v2"
            @deleteCartElement="clickDeleteElement"
          />
        </div>

        <div v-if="!readOnly" class="mt-8 lg:hidden">
          <DoiText
            :textColor="1"
            :fontFamily="4"
            :fontSize="3"
            class="ml-4 text-center"
            >Vous souhaitez partager le prix du terrain entre chaque participant
            ?
            <span
              @click="openTooltip()"
              class="material-symbols-outlined pl-2 cursor-pointer"
              >info</span
            ></DoiText
          >
          <div class="relative">
            <div
              v-if="shareBill === true"
              id="tooltip-share"
              class="absolute hidden bg-white z-10 tooltip-share w-full mx-auto"
            >
              <span
                @click="closeTooltip()"
                class="absolute right-4 top-4 cursor-pointer"
                >X</span
              >
              <DoiText
                :textColor="1"
                :fontFamily="4"
                :fontSize="3"
                class="p-10 text-center"
                >En choisissant le partage du prix du terrain, celui-ci sera
                partagé entre tous les joueurs présents sur le terrain</DoiText
              >
              <div class="absolute bg-white w-10 h-10 tic-info-share"></div>
            </div>
            <div
              v-if="shareBill === false"
              id="tooltip-no-share"
              class="absolute hidden bg-white z-10 tooltip-no-share w-full mx-auto"
            >
              <span
                @click="closeTooltip()"
                class="absolute right-4 top-4 cursor-pointer"
                >X</span
              >
              <DoiText
                :textColor="1"
                :fontFamily="4"
                :fontSize="3"
                class="p-10 text-center"
                >En choisissant de ne pas partager le prix du terrain,
                l'intégralité du prix sera à votre charge</DoiText
              >
              <div class="absolute bg-white w-10 h-10 tic-info-no-share"></div>
            </div>
          </div>
          <div class="flex justify-around mt-4">
            <DoiButton
              @click="setShare('everyone')"
              :bgColor="4"
              :textColor="1"
              class="cursor-pointer border border-black px-6"
              ><span
                class="material-icons border-2 border-black rounded-full p-1 mx-2"
                :class="[{ 'bg-green': shareBill == true }]"
                >people</span
              >OUI</DoiButton
            >
            <DoiButton
              @click="setShare('alone')"
              :bgColor="4"
              :textColor="1"
              class="cursor-pointer border border-black px-6"
              ><span
                class="material-icons border-2 border-black rounded-full p-1 mx-2"
                :class="[{ 'bg-green': shareBill == false }]"
                >person</span
              >NON</DoiButton
            >
          </div>
        </div>

        <div class="hidden lg:block" v-if="get_cart.tarif">
          <div class="flex flex-1 mt-8 items-center">
            <div class="red-line"></div>
            <DoiText
              :fontSize="5"
              :textColor="1"
              :fontFamily="0"
              class="uppercase ml-2"
              >PARTAGE DU PRIX DE LA RÉSERVATION
              <span
                @click="openTooltip()"
                class="material-symbols-outlined pl-2 cursor-pointer"
                >info</span
              ></DoiText
            >
          </div>
          <DoiText :textColor="1" :fontFamily="4" :fontSize="3" class="ml-12"
            >Vous souhaitez partager le prix du terrain entre chaque participant
            ?</DoiText
          >
          <div class="relative">
            <div
              v-if="shareBill === true"
              id="tooltip-share-lg"
              class="absolute hidden bg-white z-10 tooltip-share-lg w-full mx-auto"
            >
              <span
                @click="closeTooltip()"
                class="absolute right-4 top-4 cursor-pointer"
                >X</span
              >
              <DoiText
                :textColor="1"
                :fontFamily="4"
                :fontSize="3"
                class="p-10 text-center"
                >En choisissant le partage du prix du terrain, celui-ci sera
                partagé entre tous les joueurs présents sur le terrain</DoiText
              >
              <div class="absolute bg-white w-10 h-10 tic-info-share"></div>
            </div>
            <div
              v-if="shareBill === false"
              id="tooltip-no-share-lg"
              class="absolute hidden bg-white z-10 tooltip-no-share-lg w-full mx-auto"
            >
              <span
                @click="closeTooltip()"
                class="absolute right-4 top-4 cursor-pointer"
                >X</span
              >
              <DoiText
                :textColor="1"
                :fontFamily="4"
                :fontSize="3"
                class="p-10 text-center"
                >En choisissant de ne pas partager le prix du terrain,
                l'intégralité du prix sera à votre charge</DoiText
              >
              <div class="absolute bg-white w-10 h-10 tic-info-no-share"></div>
            </div>
          </div>
          <div class="flex justify-around mt-10 w-10/12 xl:w-1/2 -ml-4">
            <DoiButton
              @click="setShare('everyone')"
              :bgColor="4"
              :textColor="1"
              class="cursor-pointer border border-black px-16"
              ><span
                class="material-icons border-2 border-black rounded-full p-1 mx-2"
                :class="[{ 'bg-green': shareBill == true }]"
                >people</span
              >OUI</DoiButton
            >
            <DoiButton
              @click="setShare('alone')"
              :bgColor="4"
              :textColor="1"
              class="cursor-pointer border border-black px-16"
              ><span
                class="material-icons border-2 border-black rounded-full p-1 mx-2"
                :class="[{ 'bg-green': shareBill == false }]"
                >person</span
              >NON</DoiButton
            >
          </div>
        </div>

        <div>
          <div class="flex flex-1 mt-8 items-center">
            <div class="red-line"></div>
            <DoiText
              :fontSize="5"
              :textColor="1"
              :fontFamily="0"
              class="uppercase ml-2"
              >MES RÉDUCTIONS
            </DoiText>
          </div>
          <div v-if="get_cart.discounts.statusBasedDiscounts.length > 0">
            <div
              v-for="item in get_cart.discounts.statusBasedDiscounts"
              :key="`reduc-${item.id}`"
            >
              <div
                class="w-full flex items-center gap-12 p-4 border-b border-gray-300"
              >
                <img
                  @click.stop.prevent="deleteDiscount(item.id)"
                  class="cursor-pointer"
                  src="@/assets/img/trash.png"
                />
                <div>
                  <DoiText :textColor="1" :fontFamily="6" :fontSize="3">{{
                    item.label
                  }}</DoiText>
                  <DoiText :textColor="1" :fontFamily="4" :fontSize="3">{{
                    item.cumulative ? "Cumulatif" : "Non cumulatif"
                  }}</DoiText>
                </div>
              </div>
            </div>
          </div>
          <div v-if="get_cart.discounts.coupons.length > 0">
            <div
              v-for="item in get_cart.discounts.coupons"
              :key="`code-reduc-${item.id}`"
            >
              <div
                class="w-full flex items-center gap-12 p-4 border-b border-gray-300"
              >
                <img
                  @click.stop.prevent="deleteDiscount(item.id)"
                  class="cursor-pointer"
                  src="@/assets/img/trash.png"
                />
                <div class="flex items-center gap-8">
                  <div>
                    <DoiText :textColor="1" :fontFamily="6" :fontSize="3">{{
                      item.couponCode
                    }}</DoiText>
                    <DoiText
                      v-if="item.cartUsageEndDate"
                      :textColor="1"
                      :fontFamily="4"
                      :fontSize="3"
                      >Valable jusqu'au {{ item.cartUsageEndDate }} -</DoiText
                    >
                    <DoiText :textColor="1" :fontFamily="4" :fontSize="3">{{
                      item.cumulative ? "cumulatif" : "Non cumulatif"
                    }}</DoiText>
                  </div>
                  <div>
                    <DoiText
                      v-if="item.absoluteValue > 0"
                      :textColor="1"
                      :fontFamily="6"
                      :fontSize="3"
                      >{{ item.absoluteValue }} €</DoiText
                    >
                    <DoiText v-else :textColor="1" :fontFamily="6" :fontSize="3"
                      >{{ item.relativeValue }} %</DoiText
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col space-y-6 items-center sm:flex-row sm:gap-4 sm:space-y-0 sm:justify-start mt-6"
          >
            <DoiButton
              @click="openDiscount()"
              class="w-min"
              :fontFamily="6"
              :bgColor="2"
              >AJOUTER UNE RÉDUCTION</DoiButton
            >
            <DoiButton
              @click="openDiscountCode = true"
              class="w-min"
              :fontFamily="6"
              :bgColor="2"
              >AJOUTER UN CODE DE RÉDUCTION</DoiButton
            >
          </div>
        </div>

        <DoiButton
          @click="goToStep(2)"
          class="mx-auto mt-20 w-min"
          :fontFamily="6"
          >{{ get_cart.tarif ? "PROCÉDER AU PAIEMENT" : "RÉSERVER" }}</DoiButton
        >
        <div class="flex flex-1 mt-10 items-center">
          <div class="red-line"></div>
          <DoiText
            :fontSize="5"
            :textColor="1"
            :fontFamily="0"
            class="uppercase ml-2"
            >LOCATION MATÉRIELS</DoiText
          >
        </div>
        <DoiText
          v-if="isPadelPark"
          :fontSize="3"
          :textColor="1"
          :fontFamily="4"
          class="ml-10 mb-8"
          >Location de raquettes à l'accueil du site</DoiText
        >
        <DoiText
          v-else="get_cart.v2"
          :fontSize="3"
          :textColor="1"
          :fontFamily="4"
          class="ml-10 mb-8"
          >Aucun matériel disponible sur place.<br />
          Pensez à prendre votre matériel personnel.</DoiText
        >
      </div>

      <!-- STEP 2 -->
      <div v-if="step2 === true && !isLoaded">
        <div class="mt-10">
          <div
            @click="goToStep(1)"
            class="flex items-center mx-4 cursor-pointer"
          >
            <img src="@/assets/img/back-v2.png" alt="retour page precedente" />
            <DoiText
              type="span"
              class="ml-2"
              :textColor="3"
              :fontFamily="5"
              :fontSize="3"
              >RETOUR</DoiText
            >
          </div>
        </div>
        <!-- FONCTIONNEMENT NORMAL -->
        <!-- <div v-if="!get_user.portable" class="mb-4">
          <div class="lg:hidden flex flex-1 my-4 items-center">
            <div class="red-line"></div>
            <DoiText
              :fontSize="5"
              :textColor="1"
              :fontFamily="0"
              class="uppercase ml-2"
              >CONFIRMER MA RÉSERVATION PAR SMS</DoiText
            >
          </div>
          <DoiText
            :fontSize="5"
            :textColor="1"
            :fontFamily="0"
            class="hidden lg:block uppercase ml-2 mb-10 text-center"
            >CONFIRMER MA RÉSERVATION PAR SMS</DoiText
          >
          <DoiText
            :textColor="1"
            :fontFamily="4"
            :fontSize="3"
            class="mb-2 text-center"
            >Veuillez renseigner votre numéro de téléphone afin de valider votre
            paiement.</DoiText
          >
          <div
            class="flex-col flex items-center mx-auto w-full lg:max-w-5xl text-center"
          >
            <div class="flex flex-col">
              <DoiText
                :textColor="1"
                :fontFamily="4"
                :fontSize="3"
                class="mb-2 sm:mb-8"
                >Renseigner mon numéro de téléphone :</DoiText
              >
              <input
                id="portable"
                type="tel"
                pattern="[0-9]"
                required
                v-model="$v.userInfo.numero.$model"
                :error="$v.userInfo.numero.$error"
                placeholder="Numéro de téléphone"
                class="border border-gray-400 rounded-lg py-4 px-6 ml-4 focus:outline-none"
              />
            </div>
            <DoiText
              italic
              :textColor="0"
              :fontSize="1"
              v-if="!$v.userInfo.numero.required && $v.userInfo.numero.$error"
              >Ce champ est requis</DoiText
            >
          </div>
          <div class="mt-10 mx-auto sm:w-1/2 lg:max-w-5xl lg:w-4/12">
            <DoiButton
              @click="validationPhoneNumber"
              :bgColor="2"
              :fontFamily="5"
              >RECEVOIR MON CODE DE VÉRIFICATION</DoiButton
            >
          </div>
        </div> -->

        <!-- FONCTIONNEMENT TEMPORAIRE À CAUSE DE MAILJET ET DE L'ENVOI DE SMS BLOQUÉ -->
        <div v-if="!get_user.portable" class="mb-4">
          <div class="lg:hidden flex flex-1 my-4 items-center">
            <div class="red-line"></div>
            <DoiText
              :fontSize="5"
              :textColor="1"
              :fontFamily="0"
              class="uppercase ml-2"
              >CONFIRMER MA RÉSERVATION PAR SMS</DoiText
            >
          </div>
          <DoiText
            :fontSize="5"
            :textColor="1"
            :fontFamily="0"
            class="hidden lg:block uppercase ml-2 mb-10 text-center"
            >CONFIRMER MA RÉSERVATION PAR SMS</DoiText
          >
          <DoiText
            :textColor="1"
            :fontFamily="4"
            :fontSize="3"
            class="mb-2 text-center"
            >Veuillez renseigner votre numéro de téléphone afin de valider votre
            paiement.</DoiText
          >
          <div
            class="flex-col flex items-center mx-auto w-full lg:max-w-5xl text-center"
          >
            <div class="flex flex-col">
              <DoiText
                :textColor="1"
                :fontFamily="4"
                :fontSize="3"
                class="mb-2 sm:mb-8"
                >Renseigner mon numéro de téléphone :</DoiText
              >
              <input
                id="portable"
                type="tel"
                minlength="10"
                maxlength="10"
                pattern="[0-9]"
                required
                v-model="$v.userInfo.numero.$model"
                :error="$v.userInfo.numero.$error"
                placeholder="Numéro de téléphone"
                class="border border-gray-400 rounded-lg py-4 pl-14 ml-4 focus:outline-none"
              />
            </div>
            <DoiText
              italic
              :textColor="0"
              :fontSize="1"
              v-if="!$v.userInfo.numero.required && $v.userInfo.numero.$error"
              >Ce champ est requis</DoiText
            >
          </div>
          <div class="mt-10 mx-auto sm:w-1/2 lg:max-w-5xl lg:w-4/12">
            <DoiButton
              @click="validationPhoneNumber"
              :bgColor="2"
              :fontFamily="5"
              >VALIDER MON TÉLÉPHONE</DoiButton
            >
          </div>
        </div>

        <div v-if="get_user.portable">
          <div class="lg:hidden flex flex-1 my-4 items-center">
            <div class="red-line"></div>
            <DoiText
              :fontSize="5"
              :textColor="1"
              :fontFamily="0"
              class="uppercase ml-2"
              >MOYEN DE PAIEMENT</DoiText
            >
          </div>
          <DoiText
            :fontSize="5"
            :textColor="1"
            :fontFamily="0"
            class="hidden lg:block uppercase ml-2 mb-10 text-center"
            >MOYEN DE PAIEMENT</DoiText
          >
          <PaymentMeans :v2="v2" />
        </div>

        <div
          v-if="telMessageSuccess"
          class="text-center mx-auto flex justify-center"
        >
          <DoiText :textColor="3" :fontSize="3" class="">{{
            telMessageSuccess
          }}</DoiText>
        </div>
        <DoiText :textColor="3" :fontSize="3" class="text-center mx-auto">{{
          telMessageError
        }}</DoiText>

        <div v-if="!readOnly">
          <div class="mt-14 flex flex-1 justify-center">
            <div class="flex flex-col sm:flex-row justify-center">
              <DoiButton
                v-if="messageInfo"
                :disabled="isDisabled"
                @click="transformCartToOrderWithoutMessageInfo(get_cart.uuid)"
                class="mx-1"
                :fontFamily="5"
                :bgHover="1"
              >
                VALIDER LE PAIEMENT
                <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
              </DoiButton>
              <DoiButton
                v-if="!messageInfo"
                :disabled="isDisabled"
                @click="openMessageInfo = true"
                class="mx-1"
                :fontFamily="5"
                :bgHover="1"
              >
                VALIDER LE PAIEMENT
                <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
              </DoiButton>
              <!-- <DoiButton @click="openVideo = true" :fontFamily="6" :bgColor="2" :bgHover="3">VISIONNER VIDEO DE SECURITE</DoiButton> -->
              <!-- <DoiButton link="/#fit-arena-list" class="mx-1 mt-2 sm:mt-0" :bgColor="2" :bgHover="3" :fontFamily="6">AJOUTER D'AUTRES CRÉNEAUX</DoiButton> -->
              <!-- <DoiButton :disabled="isPaymentValid" @click="transformCartToOrder" class="mx-1 mt-2 sm:mt-0" :fontFamily="6">
                VALIDER
                <DoiSpinner :show="isFetching" :color="1" class="ml-3" />
              </DoiButton> -->
            </div>
          </div>
        </div>
      </div>

      <div v-if="step3 === true">
        <div class="flex flex-col justify-center my-4 items-center text-center">
          <img
            :src="require('@/assets/icons/common/check_circle.svg')"
            class="check-circle"
          />
          <template>
            <DoiText
              :fontSize="5"
              :textColor="1"
              :fontFamily="0"
              class="uppercase"
              >VOTRE RÉSERVATION EST CONFIRMÉE</DoiText
            >
            <DoiText :fontSize="3" :textColor="1" :fontFamily="0" class="my-4"
              >Vous allez recevoir un e-mail comprenant le récapitulatif de
              votre réservation.</DoiText
            >
            <DoiButton
              @click="goToDashboard()"
              class="mx-1 mt-10"
              :fontFamily="5"
              >ALLER SUR MES RÉSERVATIONS</DoiButton
            >
          </template>
        </div>
      </div>

      <div
        v-if="step1 == true || step2 == true"
        class="bg-how rounded p-8 mt-6"
      >
        <DoiText
          :fontSize="5"
          :textColor="0"
          :fontFamily="0"
          class="uppercase ml-2"
          >COMMENT ÇA MARCHE ?</DoiText
        >
        <div>
          <ol>
            <template v-if="isOrganizer">
              <DoiText
                :textColor="1"
                :fontFamily="4"
                :fontSize="3"
                class="tracking-wide mt-4"
                >1. Renseignez vos coordonnées bancaires afin de réserver
                définitivement le créneau.</DoiText
              >
              <DoiText
                :textColor="1"
                :fontFamily="4"
                :fontSize="3"
                class="tracking-wide mt-4"
                >2. Vos invités devront renseigner leurs coordonnées bancaires
                lors de l'acceptation de l'invitation.</DoiText
              >
              <DoiText
                :textColor="1"
                :fontFamily="4"
                :fontSize="3"
                class="tracking-wide mt-4"
                >3. Chaque participant sera débité, après la partie, d'une somme
                équivalente au montant total divisé par le nombre de
                participants.</DoiText
              >
              <DoiText
                :textColor="1"
                :fontFamily="4"
                :fontSize="3"
                class="tracking-wide mt-4"
                >4. L'annulation est possible au plus tard
                <DoiText
                  v-if="get_cart.v2"
                  type="span"
                  :textColor="0"
                  :fontFamily="4"
                  :fontSize="3"
                  class="tracking-wide mt-4"
                  >1h</DoiText
                >
                <DoiText
                  v-else
                  type="span"
                  :textColor="0"
                  :fontFamily="4"
                  :fontSize="3"
                  class="tracking-wide mt-4"
                  >10h</DoiText
                >
                avant le début de la partie.</DoiText
              >
            </template>
            <template v-else>
              <DoiText
                :textColor="1"
                :fontFamily="4"
                :fontSize="3"
                class="tracking-wide mt-4"
                >1. Renseignez vos coordonnées bancaires afin de valider votre
                participation.</DoiText
              >
              <DoiText
                :textColor="1"
                :fontFamily="4"
                :fontSize="3"
                class="tracking-wide mt-4"
                >2. Chaque participant sera débité, après la partie, d'une somme
                équivalente au montant total divisé par le nombre de
                participants.</DoiText
              >
              <DoiText
                :textColor="1"
                :fontFamily="4"
                :fontSize="3"
                class="tracking-wide mt-4"
                >3. L'annulation est possible au plus tard
                <DoiText
                  type="span"
                  :textColor="0"
                  :fontFamily="4"
                  :fontSize="3"
                  class="tracking-wide mt-4"
                  >48h</DoiText
                >
                avant le début de la partie.</DoiText
              >
            </template>
          </ol>
        </div>
      </div>
    </div>

    <NewModal :open="openMessageInfo" size="xs" @close-modal="open = false">
      <template slot="modal_header">
        <DoiText
          :fontSize="4"
          :textColor="1"
          :fontFamily="0"
          class="uppercase ml-2"
          >MOYEN DE PAIEMENT</DoiText
        >
      </template>
      <template slot="modal_content">
        <div class="p-4 overflow-hidden text-center">
          <DoiText :textColor="1" :fontSize="3" :fontFamily="4"
            >Si vous avez choisi de partager le paiement, vous serez débité
            uniquement du prix total à partager divisé par le nombre de
            participants.</DoiText
          ><br />
          <DoiText :textColor="1" :fontSize="3" :fontFamily="4"
            >Exemple : pour un prix terrain de 80€ et avec un total de 10
            participants, vous et chaque participant serez débités de 8€ à la
            fin de votre séance.</DoiText
          >
        </div>
        <div class="px-4 flex">
          <input
            type="checkbox"
            v-model="$v.messageInfo.$model"
            class="form-tick h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
            id="affichageMessageInfo"
            @change="handleChange()"
          />
          <label for="affichageMessageInfo">
            <DoiText :textColor="1" :fontSize="2" :fontFamily="4" class="ml-2"
              >Ne plus afficher ce message</DoiText
            >
          </label>
        </div>
      </template>
      <template slot="modal_footer">
        <div class="p-4">
          <DoiButton
            @click="transformCartToOrder(get_cart.uuid)"
            :bgHover="1"
            :fontFamily="5"
            >J'AI COMPRIS</DoiButton
          >
        </div>
      </template>
    </NewModal>
    <Modal
      :open="open"
      :displayHeader="false"
      size="xs"
      :keepratio="true"
      @close-modal="open = false"
    >
      <template #modal_content>
        <div class="w-full text-center mx-auto">
          <DoiSpinner :show="isFetching" :spinnerSize="1" class="mb-2" />
          <DoiText
            v-if="isFetching"
            :textColor="1"
            :fontSize="3"
            :fontFamily="5"
            >{{ infoMessageLoading }}</DoiText
          >
          <DoiText
            v-if="infoMessageSuccess != ''"
            :textColor="1"
            :fontSize="3"
            :fontFamily="5"
          >
            {{ infoMessageSuccess }}
            <br /><br />
            Vous allez être redirigé automatiquement, cela peut durer quelques
            secondes, merci de ne pas quitter la page avant la fin du
            chargement.
          </DoiText>
        </div>
      </template>
    </Modal>
    <NewModal
      title="VALIDATION TÉLÉPHONE"
      :open="openValidationNumber"
      size="xs"
      @close-modal="openValidationNumber = false"
    >
      <template #modal_header>
        <DoiText
          :fontSize="4"
          :textColor="1"
          :fontFamily="0"
          class="uppercase ml-2"
          >VALIDATION TÉLÉPHONE</DoiText
        >
      </template>
      <template slot="modal_content">
        <div class="px-4 overflow-hidden">
          <DoiText :textColor="1" :fontSize="3" :fontFamily="4"
            >Vous allez recevoir un sms contenant un code, veuillez l’insérer
            ci-dessous.</DoiText
          >
          <div class="flex-col items-center w-full mt-4 lg:max-w-5xl">
            <input
              type="tel"
              pattern="[0-9]"
              required
              v-model="$v.codeInfo.code.$model"
              :error="$v.codeInfo.code.$error"
              placeholder="Code de vérification"
              class="border border-gray-400 rounded-lg py-4 pl-14 focus:outline-none"
            />
            <DoiText
              italic
              :textColor="0"
              :fontSize="1"
              v-if="!$v.codeInfo.code.required && $v.codeInfo.code.$error"
              >Ce champ est requis</DoiText
            >
          </div>
        </div>
        <div class="p-2 overflow-hidden flex flex-col mt-6">
          <div class="p-4">
            <DoiButton @click="validationCode" :bgHover="1" :fontFamily="5"
              >VALIDER MON CODE</DoiButton
            >
          </div>
          <div class="flex items-center justify-center">
            <DoiText :textColor="1" :fontSize="2" :fontFamily="4" class="mr-4"
              >Vous n'avez pas reçu de SMS ?</DoiText
            >
            <div @click="validationPhoneNumber" class="cursor-pointer">
              <DoiText
                :textColor="1"
                :fontSize="2"
                :fontFamily="4"
                class="underline"
                >RENVOYER LE CODE</DoiText
              >
            </div>
          </div>
        </div>
      </template>
      <template #modal_footer> </template>
    </NewModal>
    <DiscountCodeModal
      :discountCode="discountCode"
      @closeModal="openDiscountCode = false"
      :openDiscountCode="openDiscountCode"
      @validateDiscountCode="validateDiscountCode"
    />
    <DiscountModal
      :openUserDiscount="openUserDiscount"
      :itemsDiscounts="itemsDiscounts"
      @closeModal="openUserDiscount = false"
      @validateDiscount="validateDiscount"
      @updateDiscount="updateDiscount"
    />
    <Modal
      :video="video"
      title="CONSIGNES DE SÉCURITÉ"
      :displayHeader="true"
      :open="openVideo"
      size="s"
      :keepratio="true"
      @close-modal="openVideo = false"
    >
      <template slot="modal_content">
        <div class="p-4 overflow-hidden text-center">
          <video preload="metadata" id="video" controls="true">
            <source src="../../../src/assets/video/FA_A.mp4" type="video/mp4" />
            <source
              src="../../../src/assets/video/FA_A.webm"
              type="video/webm"
            />
          </video>
        </div>
      </template>
      <template slot="modal_footer">
        <div class="block p-4 sm:hidden">
          <div>
            <button
              class="button red w-11/12 mx-auto font text-center"
              @click="submitVideo"
              type="submit"
            >
              J'atteste avoir compris<br />
              et vu la vidéo dans son intégralité
              <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
            </button>
          </div>
        </div>
        <div class="p-4 hidden sm:block">
          <div>
            <button
              class="button red mx-auto"
              @click="submitVideo"
              type="submit"
            >
              <DoiText
                >J'atteste avoir compris et vu la vidéo dans son
                intégralité</DoiText
              >
              <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import CartPaymentList from "@/views/cart/CartPaymentList";
import PaymentMeans from "@/views/reglements/PaymentMeans";

import Modal from "@/components/molecules/Modal";
import NewModal from "@/components/molecules/NewModal";
import SelectSingle from "@/components/atomic/Select.vue";
import DiscountModal from "@/components/molecules/DiscountModal.vue";
import DiscountCodeModal from "@/components/molecules/DiscountCodeModal.vue";

import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

import {
  setPhoneNumberV2,
  setValidationPhoneNumberV2,
  setValidationInfo,
} from "@/api/ValidationService";
import { getUserDiscount } from "@/api/UserService";
import { addDiscountToCart, deleteDiscountFromCart } from "@/api/CartService";

export default {
  name: "PaymentAbstract",
  data() {
    return {
      isLoaded: false,
      openUserDiscount: false,
      openDiscountCode: false,
      discountCode: "",
      itemsDiscounts: [],
      discountsTemp: [],
      discounts: [],
      discount: "",
      isFetching: false,
      open: false,
      openValidationNumber: false,
      openError: false,
      shareBill: true,
      validationCodeNumber: false,
      telMessageSuccess: "",
      telMessageError: "",
      numero: "",
      userInfo: {
        sNom: "",
        sPrenom: "",
        sEmail: "",
        numero: "",
      },
      codeInfo: {
        code: "",
      },
      openVideo: false,
      video: {},
      showSpinner: false,
      videoEnded: false,
      afficherVideoSecurite: "",
      idFitArena: "",
      validationVideo: false,
      infosVideo: {
        date: "",
        heure: "",
        videoRegardee: "",
        idFitArena: "",
      },
      inputPortable: "",
      infoMessageLoading: "",
      infoMessageSuccess: "",
      messageInfo: false,
      openMessageInfo: false,
      step1: true,
      step2: false,
      step3: false,
      v2: "false",
    };
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    isOrganizer: {
      type: Boolean,
      default: false,
    },
    isCartLoading: Boolean,
  },
  components: {
    Modal,
    NewModal,
    CartPaymentList,
    PaymentMeans,
    SelectSingle,
    DiscountModal,
    DiscountCodeModal,
  },
  validations: {
    userInfo: {
      sNom: { required },
      sPrenom: { required },
      sEmail: { required },
      numero: { required },
    },
    codeInfo: {
      code: { required },
    },
    messageInfo: {},
  },
  created() {
    this.copyUser();
  },
  async mounted() {
    this.isLoaded = true;
    this.v2 = localStorage.getItem("v2");

    await this.INIT_CART2();

    if (this.get_cart.contenu.length === 0) {
      const message = "Votre panier est vide. Veuillez réserver des créneaux.";
      if (screen.width > 640) this.$toast.error(message);
      else
        this.$toast.error(message, {
          position: "top",
        });
      this.$router.push("/#fit-arena-list");
    }

    this.mapCartInfosDiscounts();

    if (localStorage.getItem("messageInfoPayment") == null)
      this.messageInfo = false;
    else this.messageInfo = true;
    this.isLoaded = false;
  },
  computed: {
    ...mapGetters("UserModule", ["get_user"]),
    ...mapGetters("CartModule", ["get_cart"]),
    ...mapGetters("AuthModule", ["get_userIdV2"]),
    isPadelPark() {
      return localStorage.getItem("idFA") === "2";
    },
    getUserInfo() {
      return this.$store.getters["UserModule/get_user"];
    },
    isFetched() {
      return this.$store.getters["PaymentModule/isFetched"];
    },
    paymentAlias() {
      return this.$store.getters["PaymentModule/alias"];
    },
    isPaymentValid() {
      return this.paymentAlias && this.isFetched;
    },
    isDisabled() {
      // SI (l'utilisateur a un téléphone enregistré OU l'utilisateur a validé son téléphone) ET (paiement valide OU montant égal à 0)
      if (
        (this.get_user.portable || this.validationCodeNumber) &&
        (this.isPaymentValid || this.get_cart.tarif === 0)
      ) {
        // bouton actif
        return false;
      }
      // bouton inactif
      return true;
    },
    totalPrice() {
      if (this.get_cart.length > 0) {
        const cartWithTotalPrice = this.get_cart.find((cartItem) => {
          return cartItem.nTarifPanier;
        });
        return cartWithTotalPrice.nTarifPanier;
      }
      return null;
    },
    isLoading() {
      return this.$store.getters["CartModule/isCartFetching"];
    },
  },
  methods: {
    ...mapActions("CartModule", [
      "validateCart2",
      "INIT_CART2",
      "deleteCartElement2",
    ]),
    ...mapActions("UserModule", ["SET_PROFIL_INFO"]),
    handleChange() {},
    async openDiscount() {
      const idFA = localStorage.getItem("idFA");
      this.itemsDiscounts = [];
      const response = await getUserDiscount(this.get_userIdV2, idFA);
      this.discounts = response.statusBasedDiscounts;
      // si la taille de la liste temporaire n'est pas égale à la liste globale
      if (this.discounts.length !== this.discountsTemp.length) {
        // dans la liste globale des réductions
        this.discounts.forEach((discount) => {
          if (this.discountsTemp.length !== 0) {
            // dans la liste temporaire des réductions ajoutées au panier
            this.discountsTemp.forEach((item) => {
              // si la réduction n'est pas déjà dans la liste temporaire, l'ajouter dans la liste des items proposés
              if (item.label !== discount.label)
                this.itemsDiscounts.push(discount.label);
            });
          } else this.itemsDiscounts.push(discount.label);
        });
      }
      this.openUserDiscount = true;
    },
    mapCartInfosDiscounts() {
      if (this.get_cart.discounts.coupons.length > 0) {
        this.get_cart.discounts.coupons.forEach((x) => {
          if (x.hasOwnProperty("cartUsageStartDate"))
            x.cartUsageStartDate = dayjs(x.cartUsageStartDate).format(
              "DD/MM/YYYY"
            );
          if (x.hasOwnProperty("cartUsageEndDate"))
            x.cartUsageEndDate = dayjs(x.cartUsageEndDate).format("DD/MM/YYYY");
        });
      }

      if (this.get_cart.discounts.statusBasedDiscounts.length > 0) {
        this.get_cart.discounts.statusBasedDiscounts.forEach((x) => {
          if (x.hasOwnProperty("cartUsageStartDate"))
            x.cartUsageStartDate = dayjs(x.cartUsageStartDate).format(
              "DD/MM/YYYY"
            );
          if (x.hasOwnProperty("cartUsageEndDate"))
            x.cartUsageEndDate = dayjs(x.cartUsageEndDate).format("DD/MM/YYYY");
        });
      }
    },
    updateDiscount(item) {
      this.discount = item;
    },
    async deleteDiscount(id) {
      try {
        await deleteDiscountFromCart(this.get_cart.uuid, id);
        await this.INIT_CART2();
      } catch (error) {
        console.log(error);
      }
    },
    async validateDiscountCode(item) {
      const response = await addDiscountToCart(this.get_cart.uuid, {
        code: item,
      });
      if (response) {
        await this.INIT_CART2();
        this.mapCartInfosDiscounts();
      } else {
        const message = "Code de réduction non valide";
        if (screen.width > 640) {
          this.$toast.error(message);
        } else {
          this.$toast.error(message, {
            position: "top",
          });
        }
      }

      this.openDiscountCode = false;
      this.discountCode = "";
    },
    async validateDiscount() {
      const discountTemp = this.discounts.find(
        (item) => item.label === this.discount
      );

      await addDiscountToCart(this.get_cart.uuid, {
        discountId: discountTemp.id,
      });
      await this.INIT_CART2();
      this.mapCartInfosDiscounts();

      this.discountCode = "";
      this.openUserDiscount = false;
    },
    async transformCartToOrder(idReservation) {
      if (this.messageInfo) localStorage.setItem("messageInfoPayment", true);
      this.transformCartToOrderWithoutMessageInfo(idReservation);
      this.openMessageInfo = false;
    },
    async transformCartToOrderWithoutMessageInfo(idReservation) {
      this.showSpinner = true;
      this.isLoaded = true;
      await this.INIT_CART2();
      if (this.get_cart.contenu.length > 0) {
        this.isFetching = true;
        if (!this.isCartLoading) {
          try {
            const data = await this.validateCart2(this.shareBill);
            if (data !== undefined) {
              setTimeout(() => {
                this.$store.dispatch(
                  "ReservationListModule/setIdFirstReservationPanier",
                  data.listeReservations[0].id_reservation
                );

                localStorage.setItem("idReservation", idReservation);
              }, 2000);

              this.step2 = false;
              this.step3 = true;

              for (const item of this.get_cart.contenu) {
                this.clickDeleteElement(item.id);
              }
            }
          } catch (error) {
            this.openError = false;
            this.isFetching = false;
          }
        }
      } else {
        const message =
          "Votre panier a expiré. Veuillez réserver de nouveaux créneaux.";
        if (screen.width > 640) this.$toast.error(message);
        else
          this.$toast.error(message, {
            position: "top",
          });
        this.$router.push("/#fit-arena-list");
      }
      this.isLoaded = false;
      this.showSpinner = false;
    },
    copyUser() {
      this.userInfo.sNom = this.get_user.nom;
      this.userInfo.sPrenom = this.get_user.prenom;
      this.userInfo.sEmail = this.get_user.userMail;
      this.userInfo.numero = this.get_user.portable;
    },
    // validationPhoneNumber() { // TEMPORAIRE, VERSION AVEC ENVOI DE SMS
    //   this.openValidationNumber = true;
    //   setPhoneNumberV2(this.userInfo);
    //   this.router.push("/cart/payment");
    // },
    async validationPhoneNumber() {
      // TEMPORAIRE, VERSION SANS ENVOI DE SMS
      if (
        this.$v.userInfo.numero.$model === "" ||
        this.$v.userInfo.numero.$model === undefined
      )
        return;
      await setPhoneNumberV2(this.userInfo).then(async () => {
        this.telMessageSuccess =
          "Votre numéro de téléphone a bien été validé par notre système.";
        this.telMessageError = "";
        this.numero = this.userInfo.numero;
        await this.submit();
        setTimeout(() => {
          this.$router.go();
        }, 2000);
      });
    },
    async validationCode() {
      await setValidationPhoneNumberV2(this.codeInfo).then(async (response) => {
        if (response.bSucces) {
          this.telMessageSuccess =
            "Votre numéro de téléphone a bien été validé par notre système.";
          this.telMessageError = "";
          this.numero = this.userInfo.numero;
          this.openValidationNumber = false;
          this.validationCodeNumber = true;
          await this.submit();
        } else {
          this.telMessageError =
            "Votre code de validation est erroné, veuillez recommencer l'opération.";
          this.openValidationNumber = false;
        }
      });
    },
    async submit() {
      this.showSpinner = true;
      this.$v.$touch();
      if (this.$v.$invalid) this.showSpinner = false;
      else await this.setUserData();
    },
    async setUserData() {
      try {
        await this.setPhone(this.userInfo.numero);
      } catch (err) {
      } finally {
        this.showSpinner = false;
      }
    },
    setPhone(phoneNumber) {
      this.$store.commit("UserModule/SET_PHONE", phoneNumber);
    },
    submitVideo() {
      this.showSpinner = true;
      this.copyInfosVideo();
    },
    copyInfosVideo() {
      this.infosVideo.date = dayjs().format("YYYY-MM-DD");
      this.infosVideo.heure = dayjs().format("HH:mm:ss");
      this.infosVideo.idFitArena = this.get_cart[0].oReservation.nIdFitArena;
      this.infosVideo.videoRegardee = true;
      setValidationInfo(this.infosVideo);
      this.validationVideo = true;
      this.openVideo = false;
      this.showSpinner = false;
    },
    setShare(type) {
      this.shareBill = type === "alone" ? false : true;
    },
    goToStep(stepNumber) {
      switch (stepNumber) {
        case 1:
          this.step2 = false;
          this.step1 = true;
          break;
        case 2:
          this.step1 = false;
          this.step2 = true;
          break;
      }
    },
    goToDashboard() {
      this.$router.push("/profile?q=reservation&open=auto");
    },
    openTooltip() {
      if (window.screen.width < 1024) {
        if (this.shareBill) {
          document.getElementById("tooltip-share").classList.remove("hidden");
        } else {
          document
            .getElementById("tooltip-no-share")
            .classList.remove("hidden");
        }
      } else {
        if (this.shareBill) {
          document
            .getElementById("tooltip-share-lg")
            .classList.remove("hidden");
        } else {
          document
            .getElementById("tooltip-no-share-lg")
            .classList.remove("hidden");
        }
      }
    },
    closeTooltip() {
      if (window.screen.width < 1024) {
        if (this.shareBill) {
          document.getElementById("tooltip-share").classList.add("hidden");
        } else {
          document.getElementById("tooltip-no-share").classList.add("hidden");
        }
      } else {
        if (this.shareBill) {
          document.getElementById("tooltip-share-lg").classList.add("hidden");
        } else {
          document
            .getElementById("tooltip-no-share-lg")
            .classList.add("hidden");
        }
      }
    },
    async clickDeleteElement(id) {
      this.deleteCartElement2(id);
      this.$store.dispatch("CartModule/INIT_CART2");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.bg-how {
  background-color: $doi-bg-lightgrey-payment;
}

.material-icons {
  font-size: 14px;
}

.check-circle {
  color: $doi--color-color2;
  width: 7%;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" -25, "opsz" 24;
}

.red-line {
  border-top: 1px solid $doi--color-color0;
  width: 35px;
}
.line {
  background-color: $doi-bg-lightgrey-recap;
}

.red-stepper-line {
  background-color: $doi--color-color0;
}

.font {
  border-bottom: 2px solid $doi--color-color-lighter4;
  font-family: $doi--text-font-family5;
}

.font-text-active {
  color: #fff;
  font-size: $doi--text-font-size3;
  font-family: $doi--text-font-family5;
  background-color: $doi--color-color0;
  border-radius: 6px;
}

.font-text-inactive {
  color: $doi--color-color0;
  font-size: $doi--text-font-size3;
  font-family: $doi--text-font-family5;
  background-color: #fff;
  border: solid 1px $doi--color-color0;
  border-radius: 6px;
}

.bg-green {
  background-color: $doi--color-color2;
}

.tic-info-share {
  transform: rotate(50deg);
  bottom: -5px;
  left: 80px;
}

.tic-info-no-share {
  transform: rotate(50deg);
  bottom: -5px;
  right: 80px;
}

.tooltip-no-share,
.tooltip-share,
.tooltip-no-share-lg,
.tooltip-share-lg {
  box-shadow: 1px 5px 20px $doi--color-color-lighter4;
}

.tooltip-share,
.tooltip-no-share {
  bottom: 0;
}

.tooltip-no-share {
  right: 0;
}

@media (min-width: 320px) and (max-width: 500px) {
  .tooltip-share,
  .tooltip-no-share {
    width: 95%;
  }
  .step1 {
    text-align: center;
  }
  .step3 {
    text-align: center;
  }
}

@media (min-width: 500px) and (max-width: 600px) {
  .tooltip-share,
  .tooltip-no-share {
    width: 75%;
  }
}

@media (min-width: 600px) and (max-width: $doi-breakpoint-tablet) {
  .tooltip-share,
  .tooltip-no-share {
    width: 50%;
  }

  .tic-info-share,
  .tic-info-no-share {
    left: 120px;
  }
}

@media (min-width: $doi-breakpoint-tablet) and (max-width: $doi-breakpoint-desktop) {
  .tooltip-share,
  .tooltip-no-share {
    width: 50%;
  }

  .tic-info-share,
  .tic-info-no-share {
    left: 120px;
  }
}

@media (min-width: $doi-breakpoint-desktop) and (max-width: $doi-breakpoint-large-desktop) {
  .tooltip-share-lg,
  .tooltip-no-share-lg {
    width: 40%;
    bottom: -1rem;
  }

  .tic-info-share {
    left: 200px;
  }
  .tic-info-no-share {
    left: 120px;
  }

  .tooltip-no-share-lg {
    right: 13rem;
  }
}

@media (min-width: $doi-breakpoint-large-desktop) {
  .tooltip-share-lg,
  .tooltip-no-share-lg {
    width: 30%;
    bottom: -1rem;
  }

  .tic-info-share {
    left: 200px;
  }
  .tic-info-no-share {
    left: 100px;
  }

  .tooltip-no-share-lg {
    right: 35rem;
  }
}
</style>
