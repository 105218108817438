<template>
  <div class="w-full flex-1 justify-center">
    <div class="flex justify-start md:justify-center w-full mb-6">
      <DoiHeader
        :level="3"
        :fontFamily="1"
        class="text-2xl md:text-4xl tracking-wide"
        >MES PAIEMENTS</DoiHeader
      >
    </div>
    <PaymentMeans />
    <PaymentToBeSettled />
    <PaymentToCome />
    <PaymentHistory />
  </div>
</template>

<script>
import PaymentMeans from "@/views/reglements/PaymentMeans";
import PaymentHistory from "@/views/reglements/PaymentHistory";
import PaymentToBeSettled from "@/views/reglements/PaymentToBeSettled";
import PaymentToCome from "@/views/reglements/PaymentToCome";

import { mapActions } from "vuex";

export default {
  name: "Reglements",
  data() {
    return {
      paymentList: [],
      reservations: [],
    };
  },
  components: {
    PaymentMeans,
    PaymentHistory,
    PaymentToBeSettled,
    PaymentToCome,
  },
  methods: {
    ...mapActions("ReservationListModule", ["retrieveReservations"]),
    async getBooking() {
      await this.retrieveReservations().then((r) => {
        this.reservations = r.data;
      });
      if (this.reservations.length === 0) {
        const message =
          "Veuillez faire une réservation pour pouvoir modifier votre carte";
        if (screen.width > 640) {
          this.$toast.error(message);
        } else {
          this.$toast.error(message, {
            position: "top",
          });
        }
      }
    },
  },
};
</script>
