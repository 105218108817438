<template>
  <ContactInfo
    :contact="member"
    :class="{ 'border-t-2 border-gray pt-2': nIndex !== 0 }"
  >
    <button
      v-if="member.reponse === 2"
      class="button sm w-24 green"
      @click="generateQrCode(member.id_contact)"
    >
      <span>QR CODE</span>
    </button>
  </ContactInfo>
</template>

<script>
import ContactInfo from "@/components/molecules/ContactInfo";

export default {
  name: "BookingItemDetailFamily",
  components: {
    ContactInfo,
  },
  props: {
    member: Object,
    nIndex: Number,
  },
  methods: {
    generateQrCode(idContact) {
      this.$emit("generateQrCode", idContact);
    },
  },
};
</script>
