<template>
  <Modal
    title="ÉDITION DU PROFIL"
    :open="open"
    headerColor="red"
    :headerIcon="require('@/assets/icons/utils/compte/person.svg')"
    size="s"
    :keepratio="true"
    @close-modal="close"
  >
    <template slot="modal_content">
      <form @submit.prevent="submit" id="form">
        <div class="flex justify-center flex-col items-center relative mb-10">
          <img width="130" height="130" :src="profilAvatar" id="img" />
          <DoiInput
            class="underline font-input cursor-pointer"
            label="Ajouter une image"
            type="file"
            :hide="true"
            ref="avatar"
            @change="handleChangeAvatar"
          />
          <DoiText :textColor="1" :fontFamily="6" class="text-center mx-auto"
            >Veuillez sélectionner un avatar dont la taille maximum est de 5
            Mo.</DoiText
          >
        </div>
        <div class="form-group mb-2">
          <DoiInput
            v-model.trim="$v.userInfoCopy.sNom.$model"
            :error="$v.userInfoCopy.sNom.$error"
            placeholder="Nom"
          />
          <DoiText
            italic
            :textColor="0"
            :fontSize="1"
            v-if="!$v.userInfoCopy.sNom.required && $v.userInfoCopy.sNom.$error"
            >Ce champ est requis</DoiText
          >
        </div>
        <div class="form-group mb-2">
          <DoiInput
            v-model.trim="$v.userInfoCopy.sPrenom.$model"
            :error="$v.userInfoCopy.sPrenom.$error"
            placeholder="Prénom"
          />
          <DoiText
            italic
            :textColor="0"
            :fontSize="1"
            v-if="
              !$v.userInfoCopy.sPrenom.required &&
              $v.userInfoCopy.sPrenom.$error
            "
            >Ce champ est requis</DoiText
          >
        </div>
        <div class="form-group mb-2">
          <input
            maxlength="12"
            required
            v-model.trim="$v.userInfoCopy.sIdentifiant.$model"
            :error="$v.userInfoCopy.sIdentifiant.$error"
            placeholder="Pseudonyme *"
            class="doi-input"
          />
          <DoiText
            italic
            :textColor="0"
            :fontSize="1"
            v-if="
              !$v.userInfoCopy.sIdentifiant.required &&
              $v.userInfoCopy.sIdentifiant.$error
            "
            >Ce champ est requis</DoiText
          >
        </div>
        <div class="form-group mb-8">
          <input
            readonly
            type="email"
            class="input"
            v-model.trim="$v.userInfoCopy.sEmail.$model"
            :error="$v.userInfoCopy.sEmail.$error"
            placeholder="Email"
          />
        </div>
        <div class="form-group flex">
          <input
            type="checkbox"
            v-model.trim="$v.userInfoCopy.nEmailing.$model"
            class="form-tick h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
            id="emailing"
            @change="handleChange()"
          />
          <label for="emailing">
            <DoiText :textColor="3" :fontFamily="5" class="ml-2"
              >Autoriser la diffusion d'emailing</DoiText
            >
          </label>
        </div>
      </form>
    </template>

    <template slot="modal_footer">
      <div class="flex p-8 justify-center">
        <DoiButton
          @click="close"
          class="w-6/12 mr-4 tracking-widest"
          :fontFamily="6"
          :bgColor="2"
          :bgHover="3"
          >ANNULER</DoiButton
        >
        <button
          @click="submit"
          class="button red w-6/12"
          type="submit"
          :disabled="isDisabled"
        >
          <DoiText :fontFamily="6" class="tracking-widest">ENREGISTRER</DoiText>
          <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/molecules/Modal";

import { setUserAvatar } from "@/api/UserService";

import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { generateImageUrl } from "@/utils/imgUrlBuilder";

export default {
  name: "ProfilEdit",
  data() {
    return {
      showSpinner: false,
      userInfoCopy: {
        sNom: "",
        sPrenom: "",
        sEmail: "",
        sPortable: "",
        nEmailing: "",
        sIdentifiant: "",
      },
    };
  },
  components: { Modal },
  props: {
    userInfo: {
      type: Object,
      default: {},
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    userInfoCopy: {
      sNom: { required },
      sPrenom: { required },
      sEmail: { required },
      sIdentifiant: { required },
      sPortable: {},
      nEmailing: {},
    },
  },
  created() {
    this.copyUser();
  },
  computed: {
    isDisabled() {
      return this.showSpinner;
    },
    profilAvatar() {
      if (this.userInfoCopy.rAvatar !== "")
        return generateImageUrl(this.userInfoCopy.rAvatar);
      else if (this.userInfo.avatar !== "")
        return generateImageUrl(this.userInfo.avatar);
      return require("@/assets/img/no-avatar.png");
    },
  },
  methods: {
    ...mapActions("UserModule", ["SET_PROFIL_INFO"]),
    close() {
      this.$emit("close");
    },
    handleChange() {},
    copyUser() {
      this.userInfoCopy.sNom = this.userInfo.nom;
      this.userInfoCopy.sPrenom = this.userInfo.prenom;
      this.userInfoCopy.sEmail = this.userInfo.userMail;
      this.userInfoCopy.sIdentifiant = this.userInfo.identifiant;
      this.userInfoCopy.sPortable = this.userInfo.portable;
      this.userInfoCopy.rAvatar = "";
      if (this.userInfo.emailing === "false" || this.userInfo.emailing === "0")
        this.userInfoCopy.nEmailing = false;
      else this.userInfoCopy.nEmailing = true;
    },
    async handleChangeAvatar($event) {
      const file = $event.target.files[0];
      var FR = new FileReader();
      const maxAllowedSize = 5 * 1024 * 1024;

      if (file.size > maxAllowedSize) {
        // Here you can ask your users to load correct file
        file.value = "";
        const message =
          "Les fichiers doivent avoir une taille inférieure à 5 Mo";
        if (screen.width > 640) {
          this.$toast.error(message);
        } else {
          this.$toast.error(message, {
            position: "top",
          });
        }
        return;
      }
      if (/\.(jpe?g|png)$/i.test(file.value)) {
        const message = "Seulement les fichiers jpg et png sont autorisés";
        if (screen.width > 640) {
          this.$toast.error(message);
        } else {
          this.$toast.error(message, {
            position: "top",
          });
        }
        return;
      }

      FR.addEventListener("load", (e) => {
        document.getElementById("img").src = e.target.result;
        this.userInfoCopy.rAvatar = e.target.result;
      });

      FR.readAsDataURL(file);
    },
    async submit() {
      this.showSpinner = true;
      this.$v.$touch();
      if (this.$v.$invalid) this.showSpinner = false;
      else await this.setUserData();
    },
    async setUserData() {
      const data = {
        avatar: this.userInfoCopy.rAvatar,
      };

      try {
        await this.SET_PROFIL_INFO(this.userInfoCopy);
        this.close();
      } catch (err) {
      } finally {
        this.showSpinner = false;
      }

      if (this.userInfoCopy.rAvatar !== "") {
        try {
          await setUserAvatar(data);
          this.$router.go();
        } catch (e) {
        } finally {
          this.showSpinner = false;
        }
      }
    },
  },
  watch: {
    open(value) {
      if (value) this.copyUser();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.font-input {
  font-family: $doi--text-font-family6;
  color: $doi--color-color4;
  font-size: $doi--text-font-size2;
}

.input {
  width: 100%;
  padding: 10px 7px;
  border-bottom: solid 0.13rem rgb(225, 225, 225);
  background-color: $doi-bg-lightgrey-color;
}
.input::placeholder,
.doi-input::placeholder {
  color: $doi--color-color4;
  font-family: $doi--text-font-family6;
  font-size: 0.9rem;
}
.input:focus,
.doi-input:focus {
  outline: none;
}

.doi-input {
  width: 100%;
  padding: 10px 7px;
  border-bottom: solid 0.13rem rgb(225, 225, 225);
  background-color: transparent;
}
</style>
